import Data from './modules/Data';
import ShowCard from './components/ShowCard';
import ArticleCard from './components/ArticleCard';
import EpisodeCard from './components/EpisodeCard';
import { Desktop } from './modules/useResponsive';
import SearchResult from './components/SearchResult';


export default function StyleGuide() {



  return (
    <>
      <div className={"PageContent" + (Desktop() ? 'Desktop' : '')} style={Desktop() ? { padding: '2em', display: 'flex', flexWrap: 'wrap', flexDirection: 'column' } :
        { padding: '8px', display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
        {/*<div>
          <h4>show tile 1</h4>
          <Tile show={Data.all.shows[0]} />
        </div>
        <div>
          <h4>article tile 1</h4>
          <Tile article={Data.all.articles[0]} />
        </div>*/}
        <hr />
        <div>
          <h4>show tile d</h4>
          <ShowCard show={Data.all.show} detail={true} />
        </div>
        <div>
          <h4>article tile 2</h4>
          <ArticleCard article={Data.all.articles[0]} />
        </div>

        <div>
          <h4>episode tile d</h4>
          <EpisodeCard episode={Data.all.episode} detail={true} />
        </div>
        <hr />
        <div>
          <h4>article tile 3 (tall)</h4>
          <ArticleCard article={Data.all.articles[0]} detail={false} tall={true} />
        </div>
        <div>
          <h4>article tile 3</h4>
          <ArticleCard article={Data.all.articles[0]} detail={false} />
        </div>
        <div>
          <h4>show tile 3</h4>
          <ShowCard show={Data.all.shows[0]} detail={false} />
        </div>
        <div>
          <h4>episode tile 3</h4>
          <EpisodeCard episode={Data.all.episodes[0]} detail={false} />

        </div>
        <div>
          <h4>episode search result</h4>
          <SearchResult episode={Data.all.episodes[0]} />

        </div>
        <div>
          <h4>article search result</h4>
          <SearchResult article={Data.all.articles[0]} />

        </div>
        <div>
          <h4>show search result</h4>
          <SearchResult show={Data.all.shows[0]} />

        </div>

        <div style={{ backgroundColor: "#ccd", width: "40%" }}>

          <h1 className='Desktop' style={{ backgroundColor: "white" }}>header 1 desktop (48 / 57.6)</h1>
          <h1 className='Title' style={{ backgroundColor: "white" }}>header 1 mobile title (20 / 24)</h1>
          <h1 className='Desktop Title' style={{ backgroundColor: "white" }}>header 1 desktop title (48 / 57.6)</h1>

          <h2 className='Desktop' style={{ backgroundColor: "white" }}>header 2 desktop (20 / 24)</h2>

          <h2 className='Desktop Title' style={{ backgroundColor: "white" }}>header 2 desktop title (20 / 24)</h2>

          <h3 className='Desktop' style={{ backgroundColor: "white" }}>header 3 desktop (16 / 20.8)</h3>
          <h4 className='Desktop' style={{ backgroundColor: "white" }}>header 4 desktop (20 / 24)</h4>
          <h5 className='Desktop' style={{ backgroundColor: "white" }}> header 5 desktop (13 / 16.9)</h5>

          <p>paragraph lorem ipsum</p>
          <button className={(Desktop() ? 'Desktop' : '')}>button</button>
        </div>
      </div >
    </>
  )
}