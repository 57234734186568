
import { Link, useLoaderData } from "react-router-dom"
import _ from 'lodash'
import Data from "../modules/Data";
import Grid from "../components/Grid";
import TagsAndDate from "../components/TagsAndDate";
import EpisodeCard from "../components/EpisodeCard";
import Icon from "../components/Icon";
import ShowPage from "../components/ShowPage";
import { Desktop, DesktopClass } from "../modules/useResponsive";
import './Listen.scss';
import { Header, Div } from "../components/Elements";
import { DateTime } from "luxon";
import { CustomDurationFormat, CustomDateFormat } from "../modules/CustomDateTimeFormats";

export default function ListenShow() {

  var { show, schedule, errors } = useLoaderData();
  console.log("schedule", schedule)

  var nextEntry = _.first(_.get(_.first(schedule), 'schedule'));
  if (nextEntry && nextEntry.start && nextEntry.end) {

    console.log("entry", nextEntry.start);
    var start = DateTime.fromISO(nextEntry.start);//.toLocaleString(DateTime.TIME_SIMPLE);
    var end = DateTime.fromISO(nextEntry.end);//.toLocaleString(DateTime.TIME_SIMPLE);

    console.log('from ', start, "to ", end);

    var dayOfWeek = start.toFormat('EEEE');

    show.formattedTime = `${dayOfWeek}s ${CustomDurationFormat(nextEntry.start, nextEntry.end)}`;
    show.nextShowFormattedTime = CustomDateFormat(start);
  } else if (show) {
    show.formattedTime = '';
  }
  if (_.size(errors) > 0) {
    return <Div>
      <h1>Errors</h1>
      <pre>{JSON.stringify(errors, null, 2)}</pre>
      <Link to="/listen">Back to Listen</Link>
    </Div>
  }
  return <ShowPage show={show}>
    {show.nextShowFormattedTime && <>
      <h3
        className="long-border white"
        style={{ marginBottom: Desktop() ? '24px' : '12px' }}>
        Next Show
      </h3>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: Desktop() ? '7px' : '4px',
        fontSize: Desktop() ? '20px' : '13px'
      }}>
        <Icon Calendar ></Icon>
        &nbsp;{show.nextShowFormattedTime}
      </div>
    </>
    }
    <div className={DesktopClass("NextEpisode")}
      style={{ display: 'flex', alignItems: 'center', gap: Desktop() ? '32px' : '8px' }}>
      <img src={show.image || '/images/fallback.jpg'} alt={show.title} />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        fontSize: Desktop() ? '16px' : '13px',
        lineHeight: '19.2px'
      }}>
        <Header style={{ marginBottom: '20px', marginTop: '0px' }} html={show.title}></Header>
        {TagsAndDate(show, null, true)}
      </div>
    </div>
    <h3
      className="long-border white"
      style={{ marginTop: '36px' }}>Past Aired</h3>
    <Grid
      childElementFn={(episode) => {

        //          episode.show = show;
        //          episode.title = `${show.title}`;
        return <EpisodeCard episode={episode} key={episode.id} />;
      }}
      initialData={show.episodes}
      loadFn={Data.shows.episodes(show.id)}
      pageSize={4} buttonClass='light' addLinesBetween={Desktop() ? false : true} />

  </ShowPage>;
}


// inner
