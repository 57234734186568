

import { Link } from 'react-router-dom';

import ShareButtons from './ShareButtons';
import TagsAndDate from './TagsAndDate';
import AudioModule from '../modules/AudioModule';
import { Desktop } from '../modules/useResponsive';
import RouteFor from '../modules/RouteFor';
import { Div } from './Elements';
import Icon from './Icon';
import _ from '../modules/_';

import './Tile.scss';

function EpisodeDetail(episode) {

//  console.log("episode", episode)

  var show = episode.show;
  return (
    <>
      <Div className="subtitle">
        {episode.host ? <div>Hosted by <b>{episode.host}</b></div> : <></>}
        <div>Originally Aired On {episode.date} </div>
      </Div>
      <Div className="description" html={episode.description}>  </Div>
      {episode.tags.length ?
        (<Div className="tags" html={'('+episode.tags.join(", ")+')'}></Div>) : <></>}
      <Div className='socials-and-button'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <ShareButtons route={RouteFor({ episode: episode })} />
        <Link to={RouteFor({ show })}><button className='light inline'>find more</button></Link>
      </Div>

    </>
  )
}

export default function EpisodeCard({ episode, detail, style }) {

  var image = _.get(episode, 'imageMedium') || _.get(episode, 'show.image') || '/images/fallback.jpg';

  var className = `Tile episode ${detail ? "detail" : ""} ${Desktop() ? "Desktop" : "Mobile"}`;
  style = {
    ...{ backgroundImage: detail ? "" : `url(${image})` },
    ...(style || {})
  };

  const content = (
    <div className='gradient'>
      {detail ? (
        <div style={{
          display: 'flex',
          alignItems: Desktop() ? 'flex-end' : 'flex-start',
          flexDirection: Desktop() ? 'row' : 'column',
          gap: Desktop() ? '16px' : '25px',
          width: '100%'
        }}>
          <img className="episodeImage" src={image} alt={episode.name}></img>
          <div style={{
            width: Desktop() ? 'auto' : '100%'
          }}>
            {episode.audio ? 
            <Icon
            PlayCircle
            onClick={() => AudioModule.play({ episode })}
            style={{ cursor: 'pointer' }}>play</Icon>
            :
            <></>
            }
          </div>
        </div>
      ) : <></>}
      <Div style={{marginTop:'34px'}} size={detail? "28/20": "20"} lineclamp={1} className="bold" >{episode.title}</Div>
      {detail ? EpisodeDetail(episode) : TagsAndDate(episode, episode.formattedDate)}
    </div>);

  return (
    detail ? <div
      className={className} style={style}>
      {content}
    </div> : <Link className={className} style={style} to={RouteFor({ episode })}>{content}</Link>
  );
}