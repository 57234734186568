import { DateTime, Duration } from "luxon";
import _ from "lodash";
import {decode} from "html-entities";

import issues from "../test/fixtures/issues";
import issue from "../test/fixtures/issue";
import albums from "../test/fixtures/albums";
import events from "../test/fixtures/events";
import announcements from "../test/fixtures/announcements";

import StaticData from "../StaticData";

import WordpressApi from "./WordpressApi.mjs";
import * as LocalStorageWithFallback from "./LocalStorageWithFallback";
import {CustomDurationFormat, CustomDateFormat} from "./CustomDateTimeFormats";
import CachedRequest from "./CachedRequest.mjs";

function setImagePropsFromWPJson(data) {

  var imageFull, imageThumbnail, imageMedium, imageCropped, imageLarge;
  var djland_show_img = _.get(data, "djland_show_img") || _.get(data, "image");

  var alt = "CiTR Radio Station Image Placeholder";
  var featuredMediaImages = _.filter(
    _.get(data, "_embedded.wp:featuredmedia", []),
    { media_type: "image" }
  );
  if (_.isEmpty(featuredMediaImages)) {
    var featuredImage = _.get(data, "featured_image");
    imageFull = _.get(featuredImage, "sizes.medium_large.url");
    imageThumbnail = _.get(featuredImage, "sizes.medium.url");
    imageMedium = _.get(featuredImage, "sizes.medium.url");
    imageCropped = _.get(featuredImage, "sizes.mobile_full_width_crop.url");
    imageLarge = _.get(featuredImage, "sizes.large.url");
    alt = _.get(featuredImage, "alt", alt);
  } else {

    var image = _.first(featuredMediaImages);
    if (image) {
      imageFull = _.get(image, "media_details.sizes.large.source_url");
      imageThumbnail = _.get(image, "media_details.sizes.medium.source_url");
      imageMedium =
        _.get(image, "media_details.sizes.medium.source_url") ||
        _.get(image, "media_details.sizes.full.source_url");
      imageCropped = _.get(
        image,
        "media_details.sizes.mobile_full_width_crop.source_url"
      );
      imageLarge =
        _.get(image, "media_details.sizes.medium_large.source_url") ||
        _.get(image, "media_details.sizes.large.source_url") ||
        _.get(image, "media_details.sizes.full.source_url");
      alt = _.get(image, "alt_text");
    }
  }
  return {
    image: imageMedium, //!_.isEmpty(image) ? _.get(image, 'source_url') : null,
    imageThumbnail: imageThumbnail || djland_show_img,
    imageMedium: imageMedium || djland_show_img,
    imageLarge: imageLarge || djland_show_img,
    imageFull: imageFull || djland_show_img,
    imageCropped: imageCropped || djland_show_img,
    imageAlt: alt,
    imageCaption: !_.isEmpty(image) ? _.get(image, "caption.rendered") : null
  };
}

function setGenreTags(episode) {
  var genres = _.get(episode, "genres", []);
  var genres_alt = _.get(episode, "genres_alt", []);
  var genreGroupsWithSlash = _.remove(genres, tag => {
    return _.includes(tag, "/");
  });
  var genresFromSlashGroups = [];
  _.each(genreGroupsWithSlash, groupWithSlash => {
    genresFromSlashGroups = [
      ...genresFromSlashGroups,
      ..._.map(_.split(groupWithSlash, "/"),_.trim)
    ];
  });
  var tags = _.map(_.union(genres, genres_alt, genresFromSlashGroups), tag => {
    if (_.includes(tag, ",")) {
      return decode(`"${tag}"`);
    }
    return decode(tag);
  });

  return {
    tags
  }
}
const clean = {
  episode: (episode) => {

//    console.log("clean the episode", episode);

    var dateFromDJland = _.get(episode, "djland_start_time");
    var dateFromDate = _.get(episode, "date");

    var date = dateFromDate? DateTime.fromISO(dateFromDate) : DateTime.fromSQL(dateFromDJland);

    var formattedDate = CustomDateFormat(date);
    var cleaned = {
      ///      ...episode,
      id: episode.id,
      showSlug: _.get(episode, "show_slug") || _.get(episode, "show.slug"),
      date: formattedDate,
      formattedDate,
      show: !_.isEmpty(_.get(episode, "show")) ? clean.show(episode.show) : null,
      title: decode(_.get(episode, "djland_episode_title")),
      description: _.get(episode, "djland_episode_description"),
      host: _.get(episode, "djland_host_name"),
      //title: episode.name,
      audio: _.get(episode, "djland_episode_audio"),
      songs: _.get(episode, "djland_songs", []),
      //      audio:        "https://playlist.citr.ca/podcasting/audio/2023/Crimes-And-Treasons-46841-162515-January-03-20-04-13.mp3",

      ...setGenreTags(episode),
      ...setImagePropsFromWPJson(episode)
    };


//    console.log("cleaned episode", cleaned);
    return cleaned;
  },
  show: (show) => {
//    console.log("clean the show:", show);
    if (_.isEmpty(show)) {
      debugger;
    }
    show.beginningTime = DateTime.fromISO(_.get(show, 'start'));
    show.endTime = DateTime.fromISO(_.get(show, 'end'));

    var formattedTime = "";
    if (show.beginningTime.isValid && show.endTime.isValid) {
      formattedTime = `${show.beginningTime.toLocaleString(
        DateTime.TIME_SIMPLE
      )} - ${show.endTime.toLocaleString(DateTime.TIME_SIMPLE)}`;
      formattedTime = CustomDurationFormat(show.beginningTime, show.endTime);
    }


    var cleaned = {
      ///      ...show,
      id: 1 * (show.djland_show_id || show.id),
      slug: show.slug,
      host: _.get(show, "djland_host_name"),
      showTime: show.showTime,
      title: decode(_.get(show, "djland_name") || _.get(show, "title")),
      description: _.get(show, "djland_show_desc"),
      formattedTime,
      socialLinks: _.get(show, "djland_social_links"),
      ...setImagePropsFromWPJson(show),
      ...setGenreTags(show)
    };
//    console.log("cleaned show", cleaned);
    return cleaned;
  },
  issue: (issue) => {

    var pdf = null;
    if (_.isEmpty(_.get(issue, "issue_pdf"))){
      pdf = _.get(issue, "issue_pdf_link");
    } else {
      pdf = _.get(issue, "issue_pdf");
    }
    var yyyymm = _.get(issue, "issue_release_date");
    var date = null;
    if (yyyymm && !_.isEmpty(yyyymm)) {
      date = DateTime.fromFormat(yyyymm, 'yyyyLL');
    }

    var publishedDate = date? `${date.monthLong} ${date.year}` : null;

//    console.log(`Year: ${year}, Month: ${monthName}`);

    var i = {
      id: issue.term_id,
      description: issue.description ? issue.description : null,
      publishedDate,
      pdf,
      title: issue.name ? issue.name+"" : "Discorder",
      image: _.get(issue, "image.sizes.medium_large"),
      imageThumb: _.get(issue, "image.sizes.medium"),
      imageDescription: _.get(issue, "acf.issue_cover_image.alt", issue.title),
      imageLarge: _.get(issue, "image.sizes.medium_large"),
      date: issue.date ? issue.date : null,
      issue_number: _.get(issue, "issue_number"),
    };
    //    console.log(i);
    return i;
  },
  article: (article) => {
    var tags = _.flatten(_.get(article, "_embedded.wp:term", []));
    tags = _.filter(_.map(tags, "name"), (tagName) => {
      return _.find(StaticData.ColumnWPTags, { name: tagName });
    });
    var contributorsByRole = _.map(
      _.get(article, "acf.feature_contributors"),
      (c) => {
        return {
          role: _.get(c, "contributor_role"),
          customRole: _.get(c, "contributor_custom_role"),
          names: _.map(_.get(c, "contributors", []), "contributor_name")
        };
      }
    );
    var contributors = _.map(contributorsByRole, (c) => {
      var creditWords = "Credits:";
      if (c.role === "author") {
        creditWords = "Written by";
      } else if (c.role === "illustration") {
        creditWords = "Illustration by";
      } else if (c.role === "photography") {
        creditWords = "Photography by";
      } else if (c.role === "custom") {
        creditWords = c.customRole + ": ";
      }
      var names = c.names.join(", ");
      return `${creditWords} ${names}`;
    });
    var result = {
      ...article,
      ...setImagePropsFromWPJson(article),
      tags,
      title: decode(_.get(article, "title.rendered")), //article.name,
      content: _.get(article, "content.rendered"),
      description: _.get(article, "summary"),
      contributors,
      //image: "https://via.placeholder.com/900x450",
      formattedTime: DateTime.fromISO(article.date).toRelative()
    };
    return result;
  },
  album: (album) => {
    console.log("clean album", album);  
    return album;
  }
};

function schedule(show) {
  var schedule = null; //LocalStorageWithFallback.get("schedule", null);

  var shouldFetch = true;
  if (_.isString(schedule)) {
    try {
      // check if its stale
      schedule = JSON.parse(schedule);
      var date = _.get(_.first(schedule), "date");
      if (_.isString(date)) {
        var diff = DateTime.now()
          .diff(DateTime.fromISO(date), "days")
          .toObject().days;
        if (diff < 7) {
          //shouldFetch = false; dev aid
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  var p;
  if (shouldFetch) {
    var params = {};
    if (show) {
      params = {
        id: show.id,
        title: show.title
      }
    }
    p = CachedRequest("/api/schedule", params).then((r) => r.body);
  } else {
//    console.log("good cached schedule found", schedule);
    p = Promise.resolve(schedule)
  }
  return p.then(schedule => {
    if (show) {


    }
    return schedule;
  });
}

function charts(offset, size) {
      return WordpressApi.charts({offset, size}, (c)=>{
        return c;
      });
  }

const Data = {
  all: {
    episodes: [], //episodes.map(clean.episode),
    episode: {}, //clean.episode(episode),
    issues: issues.map(clean.issue),
    issue: clean.issue(issue),
    albums,
    events,
    columns: StaticData.ColumnWPTags
  },
  genres: (offset, size) => {
    return WordpressApi.genres({offset, size}).then(genres =>{
      return _.uniqBy(genres, 'name');
    });
  },
  columns: {
    list: () => Promise.resolve(StaticData.ColumnWPTags)
  },
  articles: {
    get: (id) => WordpressApi.discorderPost(id, clean.article), //Promise.resolve(clean.article(article)),
    next: (date) =>
      WordpressApi.discorderPostNext(date, clean.article).then(_.first),
    relatedTo: (id) => WordpressApi.relatedPosts(id, clean.article), //Promise.resolve(articles._.map(clean.article)),
    byColumn: (columnId, offset, size) =>
      WordpressApi.discorderPosts(
        { offset, size, tagId: columnId },
        clean.article
      ), //Promise.resolve(articles._.map(clean.article)._.filter(article => article.tags[0] === column)),
    latest: (offset, size) =>
      WordpressApi.discorderPosts({ offset, size }, clean.article),
    frontPage: () =>
      WordpressApi.discorderPosts({ size: 1 }, clean.article).then(_.first), //Promise.resolve(clean.article(article)),
    whatWereReading: () =>
      WordpressApi.discorderPosts({ offset: 0, size: 3 }, clean.article) //Promise.resolve(articles._.map(clean.article).slice(0, 3))
  },
  issues: {
    latest: () =>
      WordpressApi.issues({ offset: 0, size: 1 }, clean.issue).then(_.first),
    list: (offset, size) => {
      return WordpressApi.issues({ offset, size }, clean.issue);
    },
    get: (id) => Promise.resolve(clean.issue(issue))
  },
  shows: {
    get: (identifier) => {
      return WordpressApi.radioShow(identifier, (showWithEpisodes) => {
        return _.extend(clean.show(showWithEpisodes), {
          episodes: _.map(showWithEpisodes.episodes, clean.episode)
        });
      })
    },
    getOnAirShows: () => {
      var the_number_of_shows = 5;
      return schedule().then((schedule) => {
        var today = DateTime.now().startOf("day").toLocaleString();
//        console.log("today", today);

        var slots = [];
        _.each(schedule, (scheduledDay) => {
          slots = [...slots, ...scheduledDay.schedule];
        });
        var now = DateTime.now();
        var scheduledShows = [];
        _.each(slots, (showSlot) => {
          var slotStart = DateTime.fromISO(showSlot.start); //.toLocaleString(DateTime.TIME_SIMPLE);
          if (slotStart < now) {
            scheduledShows = [showSlot];
          } else if (_.size(scheduledShows) < the_number_of_shows) {
            scheduledShows = [...scheduledShows, showSlot];
          }
        });
        var scheduledShowsMap = {};
        var sheduledShowsWithoutSlug = [];
//        console.log("scheduled shows", scheduledShows);
        _.each(scheduledShows, (show) => {
          if (_.isString(_.get(show, "slug"))) {
            scheduledShowsMap[show.slug] = show;
          } else {
            sheduledShowsWithoutSlug.push(show);
          }
        });
        var slugs = _.keys(scheduledShowsMap);
        var madeUpId = 1;
        return WordpressApi.radioShows({ slug: slugs })
          .then(wpShows => {
            return _.map(wpShows, wpShow => {
              return {
                ...clean.show({ ...scheduledShowsMap[wpShow.slug], ...wpShow }),
                start: scheduledShowsMap[wpShow.slug].start
              };
            });
          })
          .then((cleanWpShows) => {
            var cleanedShowsWithoutSlug = _.map(sheduledShowsWithoutSlug, show => {
              return {
                ...clean.show({
                  ...show,
                  id: -(madeUpId++),
                  title: _.get(show, "show") || _.get(show, "summary", "(CiTR )")
                }),
                start: show.start
              };
            })
            var together = _.sortBy([...cleanWpShows, ...cleanedShowsWithoutSlug],'start');
//            console.log("together", together);
            return together;
          });
      });//.then(r => { console.log("goasr", r); return r; });
    },
    schedule: () => Promise.resolve([]), //shows.map(clean.show)),
    episodes: (id) => (offset, size) => {
      return WordpressApi.episodes({ id, offset, size }, clean.episode);
    },
    showsWithGenre: (genre, offset, pageSize) => {
      return WordpressApi.radioShowsWithGenre(genre, clean.show);
    },
  },
  episodes: {
    get: (id) => {
      return WordpressApi.episode(id, clean.episode)
    },
    latest: (offset, size) => {

      return WordpressApi.episodes({ offset, size }, clean.episode)
    },

    byGenre: (genreSlug, offset, size) => {
//      console.log("genreSlug", genreSlug);
      return Data.episodes.latest(offset, size);
      //PLACEHOLDER: TODO implement search episodes by genre
    },
    next: (showId) => { }
  },
  topAlbums: () => {
    return charts(0,1).then(chart =>{
      console.log("chart", chart)
      var albums = _.get(chart[0],'albums',[]);
      albums = _.map(albums, album =>{

        var links = [];
        var linkResolver = [
          {
            test: (link) => _.includes(link, ".bandcamp.com"),
            name: "bandcamp"
          },
          {
            test: (link) => _.includes(link, "spotify.com"),
            name: "spotify"
          },
          {
            test: (link) => _.includes(link, "soundcloud.com"),
            name: "soundcloud"
          }
        ];
        _.each(album.links, link =>{
          var linkName = _.find(linkResolver, r => r.test(link));
          if (linkName) {
            links.push({
              name: linkName.name,
              url: link
            });
          }
        });
        var image = _.get(album, 'album_art.sizes.medium.url');
        var imageLarge = _.get(album, 'album_art.sizes.full.url', image);
        var result = {
          artist: album.artist,
          title: album.title,
          links,
          image,
          isCancon: album.isCancon,
          isLocal: album.isLocal,
          imageLarge
        }
        console.log("cleaned album", result);
        return result;
      });
      return albums;
    });
  },
  events: {
    get: (id) => Promise.resolve(events[0]),
    upcoming: () =>
      Promise.resolve(
        _.reverse(events).filter((event) => {
          return DateTime.fromISO(event.start) > DateTime.now();
        })
      ),
    past: () =>
      Promise.resolve(
        _.reverse(events).filter((event) => {
          return DateTime.fromISO(event.start) < DateTime.now();
        })
      )
  },
  search: (q, category, page, per_page) => {
    if (_.find([q, category], _.isEmpty)) {
      return Promise.reject("missing required fields for search");
    }
    //postType: discorder, radio, playlist
    if (!page) {
      page = 1;
    }
    var query = {
      search: q,
      type: "post",
      per_page,
      page,
      _fields: "id"
    };

    var cleanFunctions = {
      articles: clean.article,
      shows: clean.show,
      episodes: clean.episode
    };
    return WordpressApi.search(query, category, cleanFunctions[category]);
  },
  charts,
  schedule,
  announcements: {
    list: () => Promise.resolve(announcements),
    get: () => Promise.resolve(announcements[0])
  } /*
  sendFeedback: (feedback) => Promise.resolve({ success: true })*/
};

export default Data;

/*
function apiRequest(url, params) {
  return fetch(url, params)
    .then(r => { return r.ok ? r.json() : []; })
    .catch(err => {
      console.error(err);
      return Promise.resolve([]);
    })
}*/
