
import { useLoaderData } from "react-router-dom"

import ShowPage from "../components/ShowPage";
export default function ListenShowEpisode() {

  var { show, episode } = useLoaderData();

//  console.log('episode', episode);
  return (
    <ShowPage show={show} episode={episode}>
    </ShowPage>
  )
}