import { Link } from "react-router-dom";

import ShareButtons from "./ShareButtons";
import { Desktop } from "../modules/useResponsive";
import RouteFor from "../modules/RouteFor";
import { Div, Button, StyledLink } from "./Elements";

import "./Tile.scss";
import AudioModule from "../modules/AudioModule";

function ShowDetail(fields) {
  return (
    <>
      <Div className="subtitle">
        {fields.host ? (
          <div>
            Hosted by <b>{fields.host}</b>
          </div>
        ) : (
          <></>
        )}
        <Div>{fields.formattedTime}</Div>
      </Div>
      <Div className="description" html={fields.description}>
        {" "}
      </Div>
      <Div
        className="socials-and-button"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <ShareButtons route={RouteFor({ show: fields })} />
      </Div>
    </>
  );
}

export default function ShowCard({
  show,
  titleSize,
  thumbnail,
  detail,
  player,
  style,
  className = "",
  d,
  m
}) {
  
  var image = thumbnail? show.imageThumbnail : show.imageLarge || show.image ;

  var className =
    className +
    ` Tile show ${detail ? "detail" : ""} ${Desktop() ? "Desktop" : "Mobile"}`;
  style = {
    ...{ backgroundImage: detail ? "" : `url(${image || '/images/fallback.jpg'})` },
    ...(style || {})
  };
  if (!titleSize){
    titleSize = "28";
  }
  
  if (player) {
    return (
      <Div
        className={className}
        style={style}
      >
        <div
          style={{
            height: 0,
            width: 0,
            margin: 0,
            padding: 0
          }}
        >
          <Button
            style={{
              //            position: 'absolute',
              width: "100px",
              marginTop: "16px",
              marginLeft: "16px"
            }}
            d={{ width: "150px" }}
            onClick={(e) => {
              AudioModule.play([show]);
            }}
          >
            listen live
          </Button>
        </div>
        <div className="gradient">
          <StyledLink
            size="20"
            className="bold"
            style={{ marginTop: "34px" }}
            to={RouteFor({ show })}
            html={show.title}
          ></StyledLink>
          <StyledLink size="20/16" to={RouteFor({ show })}>
            now playing
          </StyledLink>
        </div>
      </Div>
    );
  } else {
    const content = (
      <div className="gradient">
        <Div
          size={titleSize}
          className="bold"
          html={show.title}
        ></Div>
        {detail ? (
          ShowDetail(show)
        ) : (
          <Div size="20/16"> {show.formattedTime} </Div>
        )}
      </div>
    );

    return detail ? (
      <div className={className} style={style}>
        {content}
      </div>
    ) : (
      <StyledLink className={className} style={style} d={d} m={m} to={RouteFor({ show })}>
        {content}
      </StyledLink>
    );
  }
}
