

import EpisodeCard from "./EpisodeCard";
import ShowCard from "./ShowCard";
import { Desktop, DesktopClass } from "../modules/useResponsive";

import _ from "lodash";

export default function ShowPage({ show, episode, children }) {
//  console.log("show", show);
  var showImage = _.get(show, 'imageLarge') || _.get(show, 'imageMedium', '/images/fallback.jpg');
//  console.log("showImage", showImage);
  return (
    <div className="ListenShow full-bleed" style={{marginTop:0}}>
      <div className='meets-edge' style={{ zIndex: 0, position: 'relative' }}>

        <div style={{
          position: 'fixed',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          width: '100%',
          height: '100vh',
          backgroundImage: `url(${showImage || '/images/fallback.jpg'})`
        }} />
      </div>
      <div className="showGradient meets-edge" style={{
        display: 'flex',
        color: 'white',
        justifyContent: 'center',
        position: 'relative',

      }}>
        <div className={DesktopClass("centre-column")} >
          {episode ?
            <EpisodeCard episode={episode} detail={true} /> :
            <ShowCard show={show} detail={true} />}
        </div>
      </div>
      <div className="ShowPageContainer meets-edge" >

        <div className={DesktopClass("centre-column")} style={{
          marginBottom: Desktop() ? '72px' : '78px'
        }} >
          {children}
        </div>
      </div>

    </div>)
}